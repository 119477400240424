$(document).ready(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('#clients-slide').slick({
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 500,
		rtl: dirRtlFlag,
		responsive: [{
				breakpoint: 1400,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 993,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 765,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 550,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 415,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

})