$(function() {

  var dirRtlFlag = false;
  if ($("html").attr('dir') == 'rtl') {
    dirRtlFlag = true;
  }

  $('#hp-banners').slick({
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    rtl: dirRtlFlag, 
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    pauseOnHover: false
  });

  $(window).scroll(function(){
    var x = $(this).scrollTop(); /* scroll pos */
    var y = $("html").height();
    var z = x / y;

    curTop = (((z*800)+0));
    $("#banners-scroll").css("top", (curTop+0)+"px");
  });

  var hasWebRTC = navigator.getUserMedia ||
                  navigator.webkitGetUserMedia ||
                  navigator.mozGetUserMedia ||
                  navigator.msGetUserMedia;

  if (!hasWebRTC) {
    $('.wrap-banner-scroll').css('position','static');
  }
  


  wow = new WOW(
    {
      animateClass: 'animated',
      offset:       100,
      callback:     function(box) {
        console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
      }
    }
  );
  wow.init(); 



});
